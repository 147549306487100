import { NextRouter } from "next/router";

import {
    BOOKING_PAGE_EXCEPTIONS,
    COOKIES,
    ThirtyMinutesInSeconds,
    TwentyFourHoursInSeconds,
} from "constants/enum";
import { getCookiesValueByName, setCookie } from "utils/cookie";

export const getOpenPage = () => getCookiesValueByName(COOKIES.OPEN_PAGE);

export const getSourcePage = () => getCookiesValueByName(COOKIES.SOURCE_PAGE);

export const setVisitorTrajectoryCookie = (router: NextRouter) => {
    const { pathname } = router;

    const openPageCookies = getOpenPage();
    const sourcePageCookies = getSourcePage();

    if (openPageCookies) {
        setCookie(COOKIES.OPEN_PAGE, openPageCookies, {
            path: "/",
            "max-age": ThirtyMinutesInSeconds,
        });
    } else {
        const value = window?.location?.pathname;
        setCookie(COOKIES.OPEN_PAGE, value, {
            path: "/",
            "max-age": ThirtyMinutesInSeconds,
        });
    }

    if (!sourcePageCookies) {
        const value = window?.location?.pathname;
        setCookie(COOKIES.SOURCE_PAGE, value, {
            path: "/",
            "max-age": TwentyFourHoursInSeconds,
        });
    }

    if (!BOOKING_PAGE_EXCEPTIONS.includes(pathname)) {
        const value = window?.location?.pathname;
        setCookie(COOKIES.BOOKING_PAGE, value, {
            path: "/",
            "max-age": TwentyFourHoursInSeconds,
        });
    }
};
