import { FunctionComponent, useState, useEffect } from "react";
import { AllowedHostsList } from "constants/enum";

import { getUtmQueryParamsFromCookies } from "services/UtmService";

import { LinkProps } from "components/Link";
import {
    convertQueryStringToQueryObject,
    convertQueryObjectToQueryString,
    QueryObject,
} from "utils/queryString";

const testHost = "https://test";

export const linkWithQueryParamsHOC = (
    Component: React.FunctionComponent<LinkProps>
): FunctionComponent<LinkProps> => {
    return (props: LinkProps) => {
        const { link, ...otherProps } = props;

        if (!link) {
            return <Component {...props} />;
        }

        const [isMount, setMount] = useState(false);
        const hasWindow = typeof window !== "undefined";

        useEffect(() => {
            setMount(true);
        }, [hasWindow]);

        let queryParams: QueryObject = {};
        let qlink = link;

        try {
            const url = new URL(link, testHost);
            queryParams = convertQueryStringToQueryObject(url.search);
            qlink = url.pathname;
        } catch (error) {
            console.warn(`Error: relative link is invalid ${error}`);
        }

        try {
            const url = new URL(link);

            const hostname = url.host;
            const isAllowedHost = AllowedHostsList.indexOf(hostname) !== -1;

            if (!isAllowedHost) {
                return <Component {...props} />;
            }

            queryParams = convertQueryStringToQueryObject(url.search);
            qlink = url.origin + url.pathname;
        } catch (error) {
            // console.log(`Error: absolute link is invalid ${error}`);
        }

        if (isMount) {
            const utmQueryParams = getUtmQueryParamsFromCookies();

            Object.keys(utmQueryParams).forEach((utm) => {
                const utmToLowerCase: string = utm.replace(
                    /[A-Z]/g,
                    (letter: string) => `_${letter.toLowerCase()}`
                );

                queryParams[utmToLowerCase] = utmQueryParams[utm];
            });
        }

        const queryParamsWithUtm = convertQueryObjectToQueryString(
            queryParams as QueryObject
        );

        if (Object.keys(queryParams).length) {
            qlink = `${qlink}?${encodeURI(queryParamsWithUtm)}`;
        }

        return <Component {...otherProps} link={qlink} />;
    };
};
