module.exports = {
    languages: {
        en: "en",
        fr: "fr",
        it: "it",
        es: "es",
        pt: "pt",
        uk: "uk",
        de: "de",
    },
};
