import { Translate } from "next-translate";

import { PageContentProps } from "content/content.types";

import defaultContent from "./spain";

const pageContent = (t: Translate, { version }: PageContentProps) =>
    defaultContent(t, { version });

export default pageContent;
