import { useEffect } from "react";

import { deleteCookie } from "utils/cookie";

export const useClearCookie = (name: string, domain: string) => {
    useEffect(() => {
        deleteCookie(name, { domain });
    }, [name]);

    return true;
};
