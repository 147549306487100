import { FunctionComponent, ReactNode, PropsWithChildren } from "react";

import { LinkProps, LinkType } from "./Link.types";

const Link: FunctionComponent<PropsWithChildren<LinkProps>> = (props) => {
    const {
        link,
        className,
        name,
        type = LinkType.withName,
        children,
        ...otherProps
    } = props;

    if (!link) {
        return null;
    }

    const showName = type === LinkType.withName;

    let content: ReactNode = showName ? name : "";

    if (children) {
        content = children;
    }

    return (
        <a href={link} className={className} type={type} {...otherProps}>
            {content}
        </a>
    );
};

export default Link;
