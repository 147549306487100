export function getCookiesValueByName(name: string) {
    const relevantPair = document.cookie
        .split("; ")
        .find((pair) => pair.startsWith(name));
    if (!relevantPair) {
        return "";
    }

    return relevantPair.split("=")[1];
}

type CookieObject = {
    [key: string]: string | number | boolean;
};

type SameSite = "strict" | "lax";

type CookieOptions = {
    "max-age"?: number;
    httpOnly?: boolean;
    samesite?: SameSite;
    secure?: boolean;
    expires?: string;
    path?: string;
    domain?: string;
};

export const setCookie = (
    name: string,
    value: string,
    options: CookieOptions = {}
) => {
    const updatedCookie = name + "=" + value;
    const optionsKeys = Object.keys(options) as Array<keyof CookieOptions>;
    const cookieOptions = optionsKeys.reduce(
        (cookieOptionsValue, optionKey) => {
            let optionCookie = "; " + optionKey;
            const optionValue = options[optionKey];

            if (optionValue !== true) {
                optionCookie += "=" + String(optionValue);
            }

            return cookieOptionsValue + optionCookie;
        },
        ""
    );

    document.cookie = updatedCookie + cookieOptions;
};

export const setCookieByObjectValues = (
    object: CookieObject,
    options: CookieOptions = {}
) => {
    if (object === undefined || object === null) {
        return;
    }

    const objectKeys = Object.keys(object);
    objectKeys.map((key) =>
        setCookie(key, encodeURIComponent(String(object[key])), options)
    );
};

export const deleteCookie = (name: string, options: CookieOptions = {}) => {
    setCookie(name, "", { ...options, "max-age": -1 });
};
