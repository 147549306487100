import { createContext, Dispatch, SetStateAction } from "react";

import { ErrorType } from "layouts/pages/ErrorLayout";

export interface ContextProps {
    setErrorType: Dispatch<SetStateAction<ErrorType | undefined>>;

    errorType?: ErrorType;
}

export const initialContext = {
    setErrorType: () => {},
};

export const ErrorContext = createContext<ContextProps>(initialContext);
export const ErrorContextProvider = ErrorContext.Provider;
