import * as Sentry from "@sentry/nextjs";
import { CaptureContext } from "@sentry/types";

export enum SentryErrorTypes {
    REASON_FOR_REDIRECT_IS_ERROR_FROM_API = "The reason for redirect is error from API",
    REASON_FOR_REDIRECT_IS_ABSENT_CLINIC_ID = "The reason for redirect is absent clinicId query parameter",
    REASON_FOR_TIME_SLOTS_ERROR = "The reason for time slots error",
    REASON_FOR_GET_CITIES = "The reason for getting cities",
    REASON_FOR_PAGE_NOT_FOUND = "404: page not found",
    REASON_FOR_CREATE_CONTACT = "The reason for creating contact",
}

export const throwException = (
    error: SentryErrorTypes | string,
    captureContext: CaptureContext = { level: "error" }
) => {
    Sentry.captureException(new Error(error), captureContext);
};
