import { FC } from "react";
import classNames from "classnames/bind";
import { Text, Heading, Link } from "@smile2impress/components";

import { Container } from "components/Container";
import { Icon } from "components/Icons";
import LogoIcon from "components/Icons/icons/logo.svg";
import { SocialMediaIcon } from "components/Icons/SocialMediaIcon";
import { getAnalyticsId, GetAnalyticsIdElement } from "utils/AnalyticsId";
import { ErrorPropsReferralItem, Site, Contact } from "content/error/types";

import styles from "./ErrorReferral.module.scss";

const cx = classNames.bind(styles);

export const ErrorReferral: FC<
    ErrorPropsReferralItem & { site?: Site; contacts?: Contact[] }
> = ({ title, description, contacts, site }) => {
    return (
        <Container>
            <div className={cx("root")}>
                <Link
                    className={cx("logo")}
                    href={site?.link}
                    id={getAnalyticsId({
                        zone: "header",
                        element: GetAnalyticsIdElement.LINK,
                        label: "logo",
                    })}
                >
                    <Icon>
                        <LogoIcon />
                    </Icon>
                </Link>
                <Heading textStyle="display2" mb={["1", "2"]}>
                    {title}
                </Heading>
                <Text
                    maxWidth={["none", "480px"]}
                    ml="auto"
                    mr="auto"
                    mb={["8", "10"]}
                    textStyle="body"
                    textAlign="center"
                >
                    {description}
                </Text>
                {contacts && contacts.length > 0 && (
                    <ul className={cx("contacts-list")}>
                        {contacts.map((contact) => (
                            <li
                                className={cx("contacts-list__item")}
                                key={contact.title}
                            >
                                <Link
                                    className={cx("contacts-list__link")}
                                    href={contact.link}
                                >
                                    <Text className={cx("contacts-list__text")}>
                                        {contact.title}
                                    </Text>
                                    <Icon className={cx("contacts-list__icon")}>
                                        <SocialMediaIcon
                                            iconName={contact.icon}
                                        />
                                    </Icon>
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </Container>
    );
};
