import { COOKIES, MonthInMs } from "constants/enum";
import { getCookiesValueByName } from "utils/cookie";
import { convertQueryStringToQueryObject } from "utils/queryString";

export const storeGclid = () => {
    const queryString = location.search;
    const { gclid } = convertQueryStringToQueryObject(queryString);

    if (!gclid) {
        return;
    }

    const expiresDate = new Date(Date.now() + 3 * MonthInMs);

    document.cookie = `${
        COOKIES.GCLID_SERVICE_COOKIE
    }=${gclid};path=/;expires=${expiresDate.toUTCString()}`;
};

export const getGclid = () => {
    const gclid = getCookiesValueByName(COOKIES.GCLID_SERVICE_COOKIE);

    return gclid;
};
