import { HTMLAttributes } from "react";

export enum ContainerSize {
    DEFAULT = "default",
    FULL = "full",
}

export interface ContainerProps extends HTMLAttributes<{}> {
    size?: ContainerSize;
}
