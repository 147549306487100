module.exports = {
    countries: {
        france: "France",
        italy: "Italy",
        portugal: "Portugal",
        spain: "Spain",
        uk: "United Kingdom",
        ukraine: "Ukraine",
        us: "United States",
        germany: "Germany",
    },
};
