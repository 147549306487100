import { FC } from "react";
import useTranslation from "next-translate/useTranslation";

import { BASE_URL, SITE_URLS } from "constants/enum";
import contacts from "content/error/contacts";
import { Contact } from "content/error/types";

import { ErrorApi } from "./components/ErrorApi";
import { ErrorNotFound } from "./components/ErrorNotFound";
import { ErrorReferral } from "./components/ErrorReferral";
import { ErrorLayoutProps, ErrorType } from "./ErrorLayout.types";

export const ErrorLayout: FC<ErrorLayoutProps> = ({
    type,
    version,
    content,
}) => {
    const { t } = useTranslation();

    const site = {
        name: BASE_URL,
        link: SITE_URLS[version],
    };

    const contactsByVersion = contacts[version](t, { version }) as Contact[];

    switch (type) {
        case ErrorType.API:
            return <ErrorApi {...content[ErrorType.API]} site={site} />;

        case ErrorType.REFERRAL:
        case ErrorType.ADVOCATE:
            return (
                <ErrorReferral
                    {...content[type]}
                    site={site}
                    contacts={contactsByVersion}
                />
            );

        default:
            return <ErrorNotFound {...content[ErrorType.NOT_FOUND]} />;
    }
};
