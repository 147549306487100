import { useState, useEffect } from "react";
import Head from "next/head";

import { CanonicalServiceProps } from "./CanonicalService.types";

export const CanonicalService: React.FunctionComponent<CanonicalServiceProps> =
    () => {
        const [canonicalUrl, setCanonicalUrl] = useState<string | null>(null);

        useEffect(() => {
            const location = window?.location;

            if (location) {
                const { pathname, origin } = location;

                setCanonicalUrl(`${origin}${pathname}`);
            }
        }, []);

        return canonicalUrl ? (
            <Head>
                <link rel="canonical" href={canonicalUrl} />
            </Head>
        ) : null;
    };
