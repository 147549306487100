import { CountryVersion } from "constants/enum";

import spain from "./spain";
import portugal from "./portugal";
import italy from "./italy";
import uk from "./uk";
import us from "./us";

export default {
    [CountryVersion.SPAIN]: spain,
    [CountryVersion.CATALAN]: spain,
    [CountryVersion.PORTUGAL]: portugal,
    [CountryVersion.ITALY]: italy,
    [CountryVersion.UK]: uk,
    [CountryVersion.FRANCE]: spain,
    [CountryVersion.UKRAINE]: spain,
    [CountryVersion.GERMANY]: spain,
    [CountryVersion.US]: us,
};
