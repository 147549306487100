import { FunctionComponent, useEffect } from "react";

import { storeUtms } from "./helpers";

export const UtmService: FunctionComponent<{}> = () => {
    useEffect(() => {
        storeUtms();
    }, []);

    return null;
};
