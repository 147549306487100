import { AnchorHTMLAttributes } from "react";

export enum LinkType {
    /** default */
    withName = "withName",
    withoutName = "withoutName",
}

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    link?: string;
    className?: string;
    name?: string;
    type?: LinkType;
}
