import { CountryVersion } from "constants/enum";
import { ErrorPageContentProps } from "content/error/types";

export enum ErrorType {
    API = "api",
    REFERRAL = "referral",
    ADVOCATE = "advocate",
    NOT_FOUND = "notFound",
}

export interface ErrorLayoutProps {
    version: CountryVersion;
    content: ErrorPageContentProps;

    type?: ErrorType;
    language?: string;
}
