import { FC, useEffect } from "react";
import classNames from "classnames/bind";
import { Text, Heading, Container } from "@smile2impress/components";

import { ErrorPropsItem } from "content/error/types";
import { throwException, SentryErrorTypes } from "utils/errorLogger";
import { GetAnalyticsIdElement } from "utils/AnalyticsId";

import styles from "./ErrorNotFound.module.scss";

const cx = classNames.bind(styles);

export const ErrorNotFound: FC<ErrorPropsItem> = ({
    errorCode,
    title,
    subtitle,
}) => {
    useEffect(() => {
        throwException(SentryErrorTypes.REASON_FOR_PAGE_NOT_FOUND);
    }, []);

    return (
        <div id={GetAnalyticsIdElement.NOT_FOUND} className={cx("root")}>
            <Container size="m">
                <Text
                    mb="2"
                    textStyle="body"
                    color="text.tertiary"
                    fontSize={["96", "144", "240"]}
                >
                    {errorCode}
                </Text>
                <Heading mb={["1", "2"]} textStyle="title1">
                    {title}
                </Heading>
                <Text textStyle="body">{subtitle}</Text>
            </Container>
        </div>
    );
};
