import { FC } from "react";
import classNames from "classnames/bind";
// import * as icons from "./icons";

import { IconSvgProps as Props } from "./types";
import styles from "./Icon.module.scss";

const cx = classNames.bind(styles);

export const Icon: FC<Props> = ({ style, className, children }) => {
    // const IconSvg = icons[icon];

    // if (IconSvg) {
    //     return (
    //         <span style={style} className={cx("root", className)}>
    //             <IconSvg />
    //         </span>
    //     );
    // }

    if (children) {
        return (
            <span style={style} className={cx("root", className)}>
                {children}
            </span>
        );
    }
    return null;
};
