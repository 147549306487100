import { BaseUtmMarks, TwentyFourHoursInMs } from "constants/enum";

import { getCookiesValueByName, deleteCookie } from "utils/cookie";
import { QueryObject } from "utils/queryString";

import { UtmMarks } from "./UtmService.types";

const UTM_SERVICE_COOKIE_OLD = "impress_utm_service_cookie";
const UTM_SERVICE_COOKIE = "impress_storage-of-utm";

const convertUnderscoreToCamelCase = (key: string) => {
    const withoutUnderscore = !key.includes("_");

    if (withoutUnderscore) {
        return key;
    }

    const [utm, type] = key.split("_");
    const uppercasedType = type.slice(0, 1).toUpperCase() + type.slice(1);

    return [utm, uppercasedType].join("");
};

const getUtmMarks = (queryString: string) => {
    const UTM_RE = /utm_.*=.*/;
    // '?some=abc&another=foo' -> ['some=abc', 'another=foo']
    const arrayOfQueryParameters = queryString.replace("?", "").split("&");
    // ['utm_source=some', 'another=foo'] -> ['utm_source=some']
    const onlyUtmQueryParametersArray = arrayOfQueryParameters.filter(
        (item) => {
            const [key] = item.split("=");

            return UTM_RE.test(item) && BaseUtmMarks.includes(key);
        }
    );

    if (!onlyUtmQueryParametersArray.length) {
        return null;
    }

    return onlyUtmQueryParametersArray.reduce((acc: UtmMarks, item) => {
        const [key, value] = item.split("=");
        const keyInCamelCase = convertUnderscoreToCamelCase(key);

        acc[keyInCamelCase] = value;

        return acc;
    }, {});
};

const deleteUtms = () => {
    deleteCookie(UTM_SERVICE_COOKIE_OLD);
};

export const storeUtms = () => {
    const queryString = location.search;

    const utmMarks = getUtmMarks(queryString);

    if (!utmMarks) {
        return;
    }

    deleteUtms();

    const expiresDate = new Date(Date.now() + TwentyFourHoursInMs);

    document.cookie = `${UTM_SERVICE_COOKIE}=${JSON.stringify(
        utmMarks
    )};path=/;expires=${expiresDate.toUTCString()}`;
};

export const getUtms = () => {
    const serializedUtms = getCookiesValueByName(UTM_SERVICE_COOKIE);
    let result = null;

    if (!serializedUtms) {
        return result;
    }

    try {
        result = JSON.parse(serializedUtms);
    } catch (error) {
        console.error(error);
    }

    return result;
};

export const getUtmQueryParamsFromCookies = () => {
    const utmQueryParams: QueryObject = {};
    const utms = getUtms();

    utms &&
        Object.keys(utms).forEach((utm) => {
            const utmToLowerCase: string = utm.replace(
                /[A-Z]/g,
                (letter: string) => `_${letter.toLowerCase()}`
            );

            utmQueryParams[utmToLowerCase] = utms[utm];
        });

    return utmQueryParams;
};
