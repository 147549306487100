export enum WindowStateSize {
    DESKTOP_SMALL_MAXIMUM_SIZE = 860,
    TABLET_MAXIMUM_SIZE = 768,
    MOBILE_MAXIMUM_WIDTH = 540,
}

export const SHOW_LANGUAGE_QUESTION_EXCEPTIONS = [
    "/universalForm",
    "/bookingWidget",
    "/universalSelect",
    "/thanks",
    "/thanksMail",
    "/thanksText",
];

export const PAGES_WITHOUT_FIX_HEADER = [
    "/universalForm",
    "/universalSelect",
    "/bookingWidget",
];

export const SHOW_COOKIES_BANNER_EXCEPTIONS = [
    "/universalForm",
    "/bookingWidget",
    "/universalSelect",
    "/thanks",
    "/thanksMail",
    "/thanksText",
];

export const HIDE_SOCIAL_WIDGET = [
    "/universalForm",
    "/bookingWidget",
    "/universalSelect",
    "/thanks",
    "/thanksMail",
    "/thanksText",
    "/thanksContact",
    "/locations",
    "/blogPost",
    "/quiz",
];
import { es, ca, fr, it, pt, enUS as en, de, ru } from "date-fns/locale";

import phoneCodes from "configs/countries/phoneCodes.json";

export const AllowedHostsList = [
    "localhost",
    "smile2impress.com",
    "dev1.impress-global.com",
    "dev2.impress-global.com",
    "dev3.impress-global.com",
    "dev4.impress-global.com",
    "prelive.impress-global.com",
];

export const locales = {
    en,
    es,
    ca,
    fr,
    it,
    pt,
    uk: ru,
    de,
};

export type LocaleKeyType = keyof typeof locales;

export enum AuthStatuses {
    Initial,
    Pending,
    Success,
    Error,
}

export enum LogoTheme {
    MONOCOLOR = "monocolor",
    BICOLOR = "bicolor",
}

export enum Theme {
    DARK = "dark",
    LIGHT = "light",
    GERMANY = "germany",
}

export const ThemeConfig = {
    headerBackground: "base.primary",
    linkColor: "text.primary",
    linkMoreMenuColor: "text.primary",
    linkActiveColor: "text.accent",
    iconColor: "text.primary",
    loginButtonColor: "secondary",
    appointmentButtonColor: "primary",
};

export enum Direction {
    LEFT = "left",
    RIGHT = "right",
}

export enum Environment {
    PRODUCTION = "production",
    TESTING = "testing",
    DEVELOPMENT = "development",
}

export const WINDOW_SIZE = {
    DESKTOP_LARGE: 1440,
    DESKTOP_MIDDLE: 1365,
    DESKTOP: 1024,
    TABLET: 768,
    MOBILE: 375,
};

export const WINDOW_BREAKPOINTS = {
    DESKTOP: 1600,
    TABLET: 1366,
    MOBILE: 1024,
};

export enum Version {
    ITALY = "italy",
    SPAIN = "spain",
    PORTUGAL = "portugal",
    FRANCE = "france",
    UK = "uk",
}

export enum Language {
    ITALY = "it",
    SPAIN = "es",
    CATALONIA = "ca",
    PORTUGAL = "pt",
    FRANCE = "fr",
    ENGLISH = "en",
    UKRAINE = "uk",
    GERMANY = "de",
}

export enum COOKIES {
    EXPERIMENTS = "impress_tgid",
    TRADEDOUBLER_ID = "impress_tduid",
    IS_EXPERIMENT_SET_UP = "impress_is_experiment_set_up",
    OPEN_PAGE = "impress_open_page",
    SOURCE_PAGE = "impress_source_page",
    BOOKING_PAGE = "impress_booking-page",
    EMAIL = "impress_email",
    PHONE = "impress_phone",
    FIRST_NAME = "impress_firstname",
    LAST_NAME = "impress_lastname",
    CLINIC_ID = "impress_clinicid",
    CITY_NAME = "impress_cityName",
    FIRST_NAME_BUFFER = "impress_buffer_firstname",
    LAST_NAME_BUFFER = "impress_buffer_lastname",
    EMAIL_BUFFER = "impress_buffer_email",
    PHONE_BUFFER = "impress_buffer_phone",
    FBP = "_fbp",
    FBC = "_fbc",
    PREFERRED_LANG = "impress_preferred_lang",
    REFERRED_REGION = "impress_preferred_region",
    IMPRESS_CITY = "impress_city",
    CUSTOMER_UUID = "impress_customer_uuid",
    EXPERIMENT_NEW_WIDGET = "impress_tg",
    COOKIES_POLICY = "impress_cookies_policy",
    VERIFICATION_TOKEN = "impress_verification_token",
    GCLID_SERVICE_COOKIE = "impress_gclid",
    ACTION_POPUP = "impress_action_popup",
}

export enum ExperimentalGroupIds {
    WITH_PHONE_INPUT = 9,
}

export enum FIELDS {
    FIRST_NAME = "firstName",
    LAST_NAME = "lastName",
    EMAIL = "email",
    PHONE = "phone",
    INSTAGRAM_USER = "instagramUser",
}

export enum LOCAL_STORAGE_KEYS {
    VID = "impress_vid",
    USER_FORM_DATA = "user_form_data",
}

export enum SESSION_STORAGE_KEYS {
    SESSION_ID = "impress_session_id",
    FORM_DATA = "user_form_data",
    REFERER = "referer",
}

export enum QUERY_STRING_KEYS {
    DESIGN_SYSTEM_FLAG = "design_system",
}

export const BaseUtmMarks = [
    "utm_source",
    "utm_campaign",
    "utm_medium",
    "utm_content",
    "utm_term",
];

export enum CountryVersion {
    ITALY = "italy",
    SPAIN = "spain",
    CATALAN = "spain",
    PORTUGAL = "portugal",
    FRANCE = "france",
    UK = "uk",
    UKRAINE = "ukraine",
    GERMANY = "germany",
    US = "us",
}

export enum ContactTypes {
    PHONE = "phone",
    EMAIL = "email",
    SITE = "site",
    CHAT = "chat",
}

export enum SocialTypes {
    WA = "wa",
    TG = "tg",
    FB = "fb",
    INSTAGRAM = "instagram",
    TIKTOK = "tiktok",
    LINKDIN = "linkdin",
    CHAT = "chat",
}

export enum IconTypes {
    ARROW = "arrow",
}

export const CountryPhoneCode = {
    [CountryVersion.SPAIN]: phoneCodes.ES.code,
    [CountryVersion.CATALAN]: phoneCodes.ES.code,
    [CountryVersion.FRANCE]: phoneCodes.FR.code,
    [CountryVersion.ITALY]: phoneCodes.IT.code,
    [CountryVersion.GERMANY]: phoneCodes.DE.code,
    [CountryVersion.PORTUGAL]: phoneCodes.PT.code,
    [CountryVersion.UK]: phoneCodes.GB.code,
    [CountryVersion.UKRAINE]: phoneCodes.UA.code,
    [CountryVersion.US]: phoneCodes.US.code,
};

export const BASE_URL = "https://smile2impress.com";

export const SITE_URLS = {
    [CountryVersion.UK]: `${BASE_URL}/uk`,
    [CountryVersion.SPAIN]: `${BASE_URL}/es`,
    [CountryVersion.PORTUGAL]: `${BASE_URL}/pt`,
    [CountryVersion.ITALY]: `${BASE_URL}/it`,
    [CountryVersion.FRANCE]: `${BASE_URL}/fr`,
    [CountryVersion.CATALAN]: `${BASE_URL}/es`,
    [CountryVersion.UKRAINE]: `${BASE_URL}/ua`,
    [CountryVersion.GERMANY]: `${BASE_URL}/de`,
    [CountryVersion.US]: `${BASE_URL}/us`,
};

export const CountrySlugs = {
    [CountryVersion.SPAIN]: "esp",
    [CountryVersion.CATALAN]: "esp",
    [CountryVersion.FRANCE]: "fra",
    [CountryVersion.GERMANY]: "deu",
    [CountryVersion.ITALY]: "ita",
    [CountryVersion.PORTUGAL]: "prt",
    [CountryVersion.UKRAINE]: "ukr",
    [CountryVersion.UK]: "gbr",
    [CountryVersion.US]: "usa",
};

export enum CountryCode {
    italy = "it",
    spain = "es",
    cat = "es",
    portugal = "pt",
    france = "fr",
    uk = "gb",
    ukraine = "ua",
    germany = "de",
    us = "us",
}

export enum CountryRegion {
    italy = "it",
    spain = "es",
    cat = "es",
    portugal = "pt",
    france = "fr",
    uk = "uk",
    ukraine = "ua",
    germany = "de",
    us = "us",
}

export const CountryCodeToVersion = {
    [CountryRegion.italy]: CountryVersion.ITALY,
    [CountryRegion.spain]: CountryVersion.SPAIN,
    [CountryRegion.portugal]: CountryVersion.PORTUGAL,
    [CountryRegion.france]: CountryVersion.FRANCE,
    [CountryRegion.uk]: CountryVersion.UK,
    [CountryRegion.ukraine]: CountryVersion.UKRAINE,
    [CountryRegion.germany]: CountryVersion.GERMANY,
    [CountryRegion.us]: CountryVersion.US,
};

export const CountryCodeToLanguage = {
    [CountryRegion.italy]: Language.ITALY,
    [CountryRegion.spain]: Language.SPAIN,
    [CountryRegion.portugal]: Language.PORTUGAL,
    [CountryRegion.france]: Language.FRANCE,
    [CountryRegion.uk]: Language.ENGLISH,
    [CountryRegion.ukraine]: Language.UKRAINE,
    [CountryRegion.germany]: Language.GERMANY,
    [CountryRegion.us]: Language.ENGLISH,
};

export enum ISOCurrencyCode {
    EUR = "EUR",
    GBP = "GBP",
    CHF = "CHF",
    UAH = "UAH",
    USD = "USD",
}

export enum CurrencyCode {
    "€" = ISOCurrencyCode.EUR,
    "£" = ISOCurrencyCode.GBP,
    "CHF" = ISOCurrencyCode.CHF,
    "₴" = ISOCurrencyCode.UAH,
    "$" = ISOCurrencyCode.USD,
}

export enum ISOLanguagesCode {
    italy = "it-IT",
    spain = "es-ES",
    cat = "ca-ES",
    portugal = "pt-PT",
    france = "fr-FR",
    uk = "en-GB",
    ukraine = "uk-UA",
    germany = "de-DE",
    us = "en-US",
}

export enum ISOLanguagesCodeByLanguage {
    it = "it-IT",
    es = "es-ES",
    ca = "ca-ES",
    pt = "pt-PT",
    fr = "fr-FR",
    en = "en-GB",
    uk = "uk-UA",
    de = "de-DE",
}

export enum CurrencySymbolPosition {
    BEFORE = "before",
    AFTER = "after",
}

export enum HubspotProperties {
    LANGUAGE = "impress_preferred_language",
    OPEN_PAGE = "open_page",
    SUBMIT_PAGE = "submit_page",
    SOURCE_PAGE = "source_page",
    COUNTRY = "country",
    EXPERIMENT_ID = "experiment_id",
    LEAD_TYPE = "lead_type",
    REMINDER_CONTACT_STATUS = "impress_reminders_status",
    MARKETING_CONTACT_STATUS = "impress_marketing_status",
    INSTAGRAM_USER = "instagram_user",
    LANDING_PAGE_NAME = "landing_page_name",
    JUN_2022_DISCOUNT = "jun_2022_discount_es",
    GOOGLE_ADS_ID = "hs_google_click_id",
    CREATION_SOURCE = "contact_creation_source",
}

export enum HubspotCreationSource {
    WEBSITE = "Website",
    QUIZAUGUST2022 = "QuizAugust2022",
}

export const MIN_PHONE_LETTERS = 9;

export const BOOKING_PAGE_EXCEPTIONS = ["/thanks"];

export const ThirtyMinutesInSeconds = 30 * 60;

export const TwentyFourHoursInSeconds = 60 * 60 * 24;

export const MonthInSeconds = 60 * 60 * 24 * 30;

export const YearInSeconds = 60 * 60 * 24 * 365;

export const TwentyFourHoursInMs = 1000 * TwentyFourHoursInSeconds;
export const MonthInMs = 1000 * MonthInSeconds;

export const MillisecondsInMinute = 1000 * 60;

export const QUERY_CLINIC = "clinic";
export const BARCELONA_HIGHTLIGHT_CLINIC = 66;

export const VersionToBookingWidgetPage = {
    france: "/fr/booking-widget/",
    italy: "/it/booking-widget/",
    portugal: "/pt/booking-widget/",
    spain: "/es/booking-widget/",
    cat: "/es/booking-widget/",
    uk: "/uk/booking-widget/",
    ukraine: "/ua/booking-widget/",
    germany: "/de/booking-widget/",
    us: "/us/booking-widget/",
};

export const VersionToThanksPage = {
    france: "/fr/ouverture-prochaine/",
    italy: "/it/prossima-apertura/",
    portugal: "/pt/proxima-abertura/",
    spain: "/es/proxima-apertura/",
    cat: "/es/proxima-apertura/",
    uk: "/uk/upcoming-clinic/",
    ukraine: "/ua/dyakuyu/",
    germany: "/de/danke/",
};

export const VersionToNewThanksPage: any = {
    france: "/fr/merci/",
    italy: "/it/grazie/",
    portugal: "/pt/obrigado/",
    spain: "/es/gracias/",
    cat: "/es/gracias/",
    uk: "/uk/thanks/",
    ukraine: "/ua/dyakuyu/",
    germany: "/de/danke/",
    us: "/us/thanks/",
};

export const VersionToUniversalForm: any = {
    france: "/fr/universal-form/",
    italy: "/it/universal-form/",
    portugal: "/pt/universal-form/",
    spain: "/es/universal-form/",
    uk: "/uk/universal-form/",
    ukraine: "/ua/universal-form/",
    germany: "/de/universal-form/",
    us: "/us/universal-form/",
};

export enum PageTheme {
    RED = "red",
    WHITE = "white",
    BLUE = "blue",
}

export enum Pages {
    About = "about",
    Locations = "locations",
    WhyImpress = "whyImpress",
    Blog = "blog",
    Press = "press",
}

export enum MFA_METHODS {
    SMS = "sms_verification",
}

export enum MFA_ERROR_CODES {
    UNSUPPORTED_METHOD = "UNSUPPORTED_METHOD",
    TOKEN_EXPIRED = "TOKEN_EXPIRED",
    INVALID_CODE = "INVALID_CODE",
    TOKEN_NOT_FOUND = "TOKEN_NOT_FOUND",
    RETRY_TIMEOUT = "RETRY_TIMEOUT",
}
// Here are we have commented locales, that we will start using in future
export const LOCALE_CODE = {
    italy: {
        // it: "it-IT",
        // en: "en-ES", // There are no en-IT language in Lokalise
        // ch: "it-CH",
        it: "it",
        en: "en",
    },
    spain: {
        // es: "es-ES",
        cat: "ca",
        // en: "en-ES",
        es: "es",
        en: "en",
    },
    portugal: {
        // pt: "pt-PT",
        // en: "en-ES", // There are no en-PT language in Lokalise
        pt: "pt",
        en: "en",
    },
    france: {
        // fr: "fr-FR",
        // en: "en-FR",
        fr: "fr",
    },
    uk: {
        // en: "en-GB", // Should be changed to en-GB
        en: "en",
    },
    ukraine: {
        // uk: "uk-UA",
        uk: "uk",
    },
    germany: {
        // de: "de-DE",
        de: "de",
    },
    us: {
        en: "en-US",
        es: "es-US",
    },
};
