// TODO https://impress-global.atlassian.net/browse/DEV-401
module.exports = {
    regionSupportedLanguageConfig: {
        France: {
            country: "France",
            languages: ["fr"],
            defaultLanguage: "fr",
        },
        Italy: {
            country: "Italy",
            languages: ["it", "en"],
            defaultLanguage: "it",
        },
        Portugal: {
            country: "Portugal",
            languages: ["pt", "en"],
            defaultLanguage: "pt",
        },
        Spain: {
            country: "Spain",
            languages: ["es", "en"],
            defaultLanguage: "es",
        },
        Ukraine: {
            country: "Ukraine",
            languages: ["uk"],
            defaultLanguage: "uk",
        },
        Germany: {
            country: "Germany",
            languages: ["de"],
            defaultLanguage: "de",
        },
        "United Kingdom": {
            country: "United Kingdom",
            languages: ["en"],
            defaultLanguage: "en",
        },
        "United States": {
            country: "United States",
            languages: ["en"],
            defaultLanguage: "en",
        },
    },
};
