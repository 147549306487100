import { CountryVersion } from "constants/enum";

export const VERSION_TO_HREF_LANG = {
    [CountryVersion.SPAIN]: "es-ES",
    [CountryVersion.PORTUGAL]: "pt-PT",
    [CountryVersion.ITALY]: "it-IT",
    [CountryVersion.FRANCE]: "fr-FR",
    [CountryVersion.UK]: "en-GB",
    [CountryVersion.UKRAINE]: "uk-UA",
    [CountryVersion.GERMANY]: "de-DE",
    [CountryVersion.US]: "en-US",
};
