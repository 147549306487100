import { NextComponentType, NextPageContext } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import appWithI18n from "next-translate/appWithI18n";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { useState } from "react";
import { ThemeProvider } from "@smile2impress/components";
import { defaultTheme } from "@smile2impress/themes";
import useTranslation from "next-translate/useTranslation";

import { UtmService } from "services/UtmService";
import { CanonicalService } from "services/CanonicalService";
import { VisitorTrajectoryService } from "services/VisitorTrajectoryService";
import { GoogleAdsService } from "services/GoogleAdsService";
import { Language, Environment, COOKIES } from "constants/enum";
import { CountryVersion } from "constants/enum";
import { amplifyConfig } from "configs/amplifyConfig";
import { HreflangService, CountryUrls } from "services/HreflangService";

import "intl-pluralrules";
import "@smile2impress/editorjs-blocks/lib/index.css";
import "style/fonts.css";
import "style/variables.css";
import "style/styles.css";
import { ErrorContextProvider } from "context/errorContext";
import { ErrorLayout, ErrorType } from "layouts/pages/ErrorLayout";
import errorPageContent from "content/error";
import { useClearCookie } from "hooks/useClearCookie";

import i18nConfig from "../i18n";

interface MyAppProps extends AppProps<{ content?: unknown }> {
    Component: NextComponentType<
        NextPageContext,
        unknown,
        {
            content?: unknown;
            pageData?: unknown;
            version?: CountryVersion;
            language?: Language;
        }
    >;
    pageProps: {
        version: CountryVersion;
        language: Language;
        pageUrls: CountryUrls;
        contentFolder: string;
        hideRegionSelector: boolean;

        pageData?: unknown;

        isMainPage?: string;
        statusCode?: string;
        withHeader?: boolean;
        withFooter?: boolean;
    };
}

const MyApp = ({ Component, pageProps }: MyAppProps) => {
    Amplify.configure(amplifyConfig);
    Auth.configure(amplifyConfig);

    const { t } = useTranslation();

    const { version = CountryVersion.SPAIN, pageUrls } = pageProps;

    const [errorType, setErrorType] = useState<ErrorType | undefined>();
    const errorContent = errorPageContent[version](t);

    useClearCookie(COOKIES.PREFERRED_LANG, ".smile2impress.com");

    return (
        <ErrorContextProvider value={{ errorType, setErrorType }}>
            <>
                <UtmService />
                <GoogleAdsService />
                <VisitorTrajectoryService />
                <HreflangService urls={pageUrls} />
                <CanonicalService />
                <Head>
                    {process.env.ENV === Environment.PRODUCTION && (
                        <>
                            {/* Google Tag Manager */}
                            <script
                                id="google-tag"
                                async
                                src="/scripts/google-tag.js"
                            ></script>
                            {/* End Google Tag Manager */}
                        </>
                    )}
                    {process.env.ENV === Environment.PRODUCTION &&
                        (version === CountryVersion.US ? (
                            <>
                                {/* TikTok Pixel Code USA Start */}
                                <script
                                    id="tiktok"
                                    async
                                    src="/scripts/tiktok-usa.js"
                                ></script>
                                {/* TikTok Pixel Code USA End */}
                            </>
                        ) : (
                            <>
                                {/* TikTok Pixel Code Start */}
                                <script
                                    id="tiktok"
                                    async
                                    src="/scripts/tiktok.js"
                                ></script>
                                {/* TikTok Pixel Code End */}
                            </>
                        ))}
                    {process.env.ENV === "production" &&
                        (version === CountryVersion.FRANCE ||
                            version === CountryVersion.GERMANY ||
                            version === CountryVersion.UK) && (
                            <>
                                {/* Snap Pixel Code */}
                                <script
                                    id="snapchat-pixel"
                                    async
                                    src="/scripts/snapchat-pixel.js"
                                ></script>
                                {/* End Snap Pixel Code */}
                            </>
                        )}
                    {version === CountryVersion.GERMANY && (
                        <link
                            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Source+Serif+Pro:wght@700&display=swap"
                            rel="stylesheet"
                        />
                    )}
                    {version === CountryVersion.UKRAINE && (
                        <link
                            href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"
                            rel="stylesheet"
                        />
                    )}
                    {process.env.ENV === "production" &&
                        version === CountryVersion.US && (
                            <>
                                {/* Snap Pixel Code US */}
                                <script
                                    id="snapchat-pixel"
                                    async
                                    src="/scripts/snapchat-pixel-us.js"
                                ></script>
                                {/* End Snap Pixel Code US */}
                            </>
                        )}
                </Head>
                {errorType ? (
                    <ThemeProvider theme={defaultTheme}>
                        <ErrorLayout
                            type={errorType}
                            version={version}
                            content={errorContent}
                        />
                    </ThemeProvider>
                ) : (
                    <Component {...pageProps} />
                )}
            </>
        </ErrorContextProvider>
    );
};

export default appWithI18n(MyApp as any, {
    ...i18nConfig,
    // Set to false if you want to load all the namespaces on _app.js getInitialProps
    skipInitialProps: true,
});
