const liveUserPoolClientId = "6p48us7ml8l483r5k2oss3e8";
const liveUserPoolId = "eu-central-1_V0msTK5hP";

const devUserPoolClientId = "45tjakqnn2updtecucmb563rvp";
const devUserPoolId = "eu-central-1_Yz78qfeni";

const isProduction = process.env.ENV === "production";

export const amplifyConfig = {
    Auth: {
        region: "eu-central-1",
        userPoolId: isProduction ? liveUserPoolId : devUserPoolId,
        userPoolWebClientId: isProduction
            ? liveUserPoolClientId
            : devUserPoolClientId,
        authenticationFlowType: "CUSTOM_AUTH",
        cookieStorage: {
            domain: process.env.COGNITO_COOKIE_DOMAIN || ".smile2impress.com",
            path: "/",
            expires: 365,
            sameSite: "strict",
            secure: isProduction,
        },
    },
};
