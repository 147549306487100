import { Translate } from "next-translate";

import contacts from "configs/contacts.json";
import { ContactTypes } from "constants/enum";
import { PageContentProps } from "content/content.types";

const pageContent = (t: Translate, { version }: PageContentProps) => [
    {
        link: `tel:${contacts.phone[version].link}`,
        icon: ContactTypes.PHONE,
        title: contacts.phone[version].link,
    },
    {
        link: contacts.email[version].link,
        icon: ContactTypes.EMAIL,
        title: t("global:email"),
    },
];

export default pageContent;
