const versions = {
    france: "france",
    germany: "germany",
    italy: "italy",
    portugal: "portugal",
    spain: "spain",
    uk: "uk",
    ukraine: "ukraine",
    us: "us",
};

module.exports = {
    versions,
};
