import { FC, useEffect } from "react";

import { storeGclid } from "./helpers";

export const GoogleAdsService: FC<{}> = () => {
    useEffect(() => {
        storeGclid();
    }, []);

    return null;
};
