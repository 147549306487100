import { FC } from "react";
import {
    Icon,
    WhatsappThinOutlineIcon,
    TelegramOutlineIcon,
    MailThinOutlineIcon,
    FacebookThinOutlineIcon,
    InstagramThinOutlineIcon,
    TiktokThinOutlineIcon,
    LinkedinThinOutlineIcon,
    PhoneThinOutlineIcon,
    BubblesThinOutlineIcon,
} from "@smile2impress/components";
import { As } from "@chakra-ui/system";

import { ContactTypes, SocialTypes, IconTypes } from "constants/enum";

export type SocialIconNameType =
    | Partial<ContactTypes>
    | Partial<SocialTypes>
    | Partial<IconTypes>;

const iconsByName: { [key in string]: As<JSX.Element> } = {
    [ContactTypes.PHONE]: PhoneThinOutlineIcon,
    [SocialTypes.WA]: WhatsappThinOutlineIcon,
    [SocialTypes.TG]: TelegramOutlineIcon,
    [ContactTypes.EMAIL]: MailThinOutlineIcon,
    [SocialTypes.FB]: FacebookThinOutlineIcon,
    [SocialTypes.INSTAGRAM]: InstagramThinOutlineIcon,
    [SocialTypes.TIKTOK]: TiktokThinOutlineIcon,
    [SocialTypes.LINKDIN]: LinkedinThinOutlineIcon,
    [SocialTypes.CHAT]: BubblesThinOutlineIcon,
};

export const SocialMediaIcon: FC<{
    iconName: SocialIconNameType;
}> = ({ iconName }) => {
    return (
        <Icon
            sx={{
                // @TODOFIX before re-design icons in figma
                path: {
                    strokeWidth:
                        iconName === SocialTypes.WA ||
                        iconName === SocialTypes.INSTAGRAM ||
                        iconName === ContactTypes.EMAIL
                            ? 0
                            : "initial",
                },
            }}
            as={iconsByName[iconName]}
        />
    );
};
