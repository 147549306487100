import { FC } from "react";
import classNames from "classnames/bind";
import {
    Heading,
    Text,
    Container as ChakraContainer,
    Button,
    Flex,
} from "@smile2impress/components";

import { Container } from "components/Container";
import { Icon } from "components/Icons";
import LogoIcon from "components/Icons/icons/logo.svg";
import { Link } from "components/Link";
import { getAnalyticsId, GetAnalyticsIdElement } from "utils/AnalyticsId";
import { ErrorPropsItem, Site } from "content/error/types";

import styles from "./ErrorApi.module.scss";

const cx = classNames.bind(styles);

export const ErrorApi: FC<ErrorPropsItem & { site?: Site }> = ({
    title,
    site,
    subtitle,
    button,
}) => {
    return (
        <Container className={cx("container")}>
            <Link
                {...site}
                id={getAnalyticsId({
                    zone: "header",
                    element: GetAnalyticsIdElement.LINK,
                    label: "logo",
                })}
                className={cx("logo")}
            >
                <Icon>
                    <LogoIcon />
                </Icon>
            </Link>
            <Flex justifyContent="center">
                <ChakraContainer size="m">
                    <Heading mb={["1", "2"]} as="h3" textStyle="title1">
                        {title}
                    </Heading>
                    <Text mb="10" textStyle="body">
                        {subtitle}
                    </Text>
                </ChakraContainer>
            </Flex>
            {button?.text && (
                <Flex justifyContent="center">
                    <Button onClick={window.location.reload}>
                        {button.text}
                    </Button>
                </Flex>
            )}
        </Container>
    );
};
