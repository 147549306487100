module.exports = {
    locales: ["en", "es", "it", "pt", "fr", "uk", "de", "en-US"],
    defaultLocale: "es",
    pages: {
        "/_error": ["global", "meta", "error"],
        "/main": [
            "main",
            "global",
            "faq",
            "meta",
            "commonPricesNew",
            "common-form",
        ],
        "/teen": [
            "teen",
            "global",
            "faq",
            "common-form",
            "meta",
            "commonPricesNew",
            "commonVerification",
        ],
        "/prices": [
            "pricesPage",
            "global",
            "faq",
            "common-form",
            "meta",
            "commonPricesNew",
            "commonVerification",
        ],
        "/whyImpress": ["whyImpress", "global", "faq", "meta"],
        "/howItWorks": [
            "howItWorks",
            "global",
            "faq",
            "common-form",
            "meta",
            "commonVerification",
        ],
        "/results": ["global", "faq", "results", "ourResults", "meta"],
        "/universalForm": [
            "global",
            "universalForm",
            "common-form",
            "meta",
            "commonVerification",
        ],
        "/locations": ["locations", "global", "meta", "common-form"],
        "/retainersSubscription": [
            "retainersSubscriptionPrices",
            "faq",
            "retainersSubscription",
            "global",
            "meta",
        ],
        "/thanks": ["thanks", "global", "meta"],
        "/thanksMail": ["thanks-mail", "global", "meta"],
        "/thanksSign": ["thanksSign", "global", "meta"],
        "/thanksText": ["thanks-text", "meta"],
        "/universalSelect": ["global", "universalSelectLong", "meta"],
        "/appointmentCity": ["global", "meta"],
        "/bookingWidget": [
            "common-form",
            "commonBookingWidget",
            "global",
            "meta",
        ],
        "/blog": ["global", "blog", "meta"],
        "/blogPost": ["global", "blog", "meta", "faq"],
        "/clinicPage": ["global", "meta"],
        "/whoWeAre": ["global", "meta"],
        "/about": ["global", "about", "faq", "meta"],
        "/firstVisit": ["global", "firstVisit", "commonPricesNew", "meta"],
        "/newCity": ["global", "newCity"],
        "/referralForFriends": [
            "global",
            "referralForFriends",
            "commonVerification",
            "error",
        ],
        "/referral": [
            "global",
            "referral",
            "ourResults",
            "common-form",
            "commonVerification",
            "error",
        ],
        "/quiz": ["global", "common-form", "quiz", "meta"],
        "/c2": [
            "global",
            "c2",
            "commonVerification",
            "commonBookingWidget",
            "error",
        ],
        "/advanced": [
            "common-form",
            "global",
            "commonPricesNew",
            "advanced",
            "meta",
        ],
        "/thanksContact": ["global", "meta", "thanksContact"],
        "/kids": ["global", "meta", "kids", "commonPricesNew", "common-form"],
        "/associatedClinics": ["global", "meta", "faq", "associatedClinics"],
        "/kids": ["global", "meta", "kids", "commonPricesNew", "common-form"],
    },
    loader: false,
    loadLocaleFrom: (locale, namespace) =>
        import(`./locales/${locale}/${namespace}`).then((m) => m.default),
};
