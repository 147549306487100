import { useRouter } from "next/router";

import { COOKIES } from "constants/enum";

import { getCookiesValueByName } from "./cookie";

type GetAnalyticsIdOptions = {
    zone: string;
    element?: GetAnalyticsIdElement;
    label?: number | string;
};

export enum GetAnalyticsIdElement {
    INPUT = "input",
    BUTTON = "button",
    LINK = "link",
    GTM_LOGIN_BUTTON = "gtm-login-button",
    NOT_FOUND = "not-found",
}

const NONE = "none";

export const getAnalyticsId = ({
    zone,
    element = GetAnalyticsIdElement.BUTTON,
    label = NONE,
}: GetAnalyticsIdOptions) => {
    let experiments;

    const router = useRouter();

    const pathname = router?.asPath
        ?.split("?")[0]
        .split("/")
        .filter(Boolean)
        .join("-");

    try {
        const experimentCookieValue = getCookiesValueByName(
            COOKIES.EXPERIMENTS
        );

        experiments = experimentCookieValue
            ? `${COOKIES.EXPERIMENTS}-${experimentCookieValue
                  .split(",")
                  .join("-")}`
            : NONE;
    } catch (ex) {
        experiments = NONE;
    }

    return [pathname, zone, element, label, NONE, experiments].join("_");
};
