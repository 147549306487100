import { FC, useState } from "react";
import { nanoid } from "nanoid";
import Head from "next/head";
import { isEmpty } from "ramda";

import { CountryVersion } from "constants/enum";

import { VERSION_TO_HREF_LANG } from "./constants";
import { HreflangServiceProps, CountryUrls } from "./HreflangService.types";

const HOME_PAGE_URL = "https://smile2impress.com";

const getLink = (url: string) => {
    return `${HOME_PAGE_URL}${url}`;
};

const renderLinks = (urls: CountryUrls) => {
    return Object.keys(urls).map((version) => {
        // @ts-ignore
        const url = urls[version as keyof CountryUrls];

        if (!url) {
            return;
        }

        // //Example of using https://github.com/ai/nanoid#react
        const [linkKey] = useState(nanoid);
        const linkHref = getLink(url);

        return (
            <link
                rel="alternate"
                href={linkHref}
                // @ts-ignore
                hrefLang={VERSION_TO_HREF_LANG[version as CountryVersion]}
                key={linkKey}
            />
        );
    });
};

export const HreflangService: FC<HreflangServiceProps> = (props) => {
    const { urls } = props;
    const isUrlsEmpty = !urls || isEmpty(urls);

    if (isUrlsEmpty) {
        return null;
    }

    return (
        <Head>
            {renderLinks(urls)}
            {/* <link rel="alternate" href={HOME_PAGE_URL} hrefLang="x-default" /> */}
        </Head>
    );
};
