import { FunctionComponent, useEffect } from "react";
import { useRouter } from "next/router";

import { setVisitorTrajectoryCookie } from "./helpers";

export const VisitorTrajectoryService: FunctionComponent<{}> = () => {
    const router = useRouter();

    useEffect(() => {
        setVisitorTrajectoryCookie(router);
    }, []);

    return null;
};
