import { Translate } from "next-translate";

import { ErrorPageContentProps } from "./types";

const pageContent = (t: Translate): ErrorPageContentProps => {
    return {
        api: {
            title: t("error:apiErrorTitle"),
            subtitle: t("error:apiErrorSubtitle"),
            button: {
                text: t("error:apiErrorAction"),
            },
        },
        notFound: {
            errorCode: "404",
            title: t("error:notFoundErrorTitle"),
            subtitle: t("error:notFoundErrorSubtitle"),
        },
        referral: {
            title: t("error:referralErrorTitle"),
            description: t("error:referralErrorDescription"),
        },
        advocate: {
            title: t("error:advocateErrorTitle"),
            description: t("error:advocateErrorDescription"),
        },
    };
};

export default pageContent;
