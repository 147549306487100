const { versions } = require("./versions");
const { countries } = require("./countriesConfig");
const { languages } = require("./languagesConfig");
const {
    regionSupportedLanguageConfig,
} = require("./regionSupportedLanguageConfig");

module.exports = {
    versions,
    countryToVersion: {
        [countries.france]: versions.france,
        [countries.italy]: versions.italy,
        [countries.portugal]: versions.portugal,
        [countries.spain]: versions.spain,
        [countries.ukraine]: versions.ukraine,
        [countries.germany]: versions.germany,
        [countries.uk]: versions.uk,
        [countries.us]: versions.us,
    },
    versionToCountry: {
        [versions.france]: countries.france,
        [versions.italy]: countries.italy,
        [versions.portugal]: countries.portugal,
        [versions.spain]: countries.spain,
        [versions.ukraine]: countries.ukraine,
        [versions.germany]: countries.germany,
        [versions.uk]: countries.uk,
        [versions.us]: countries.us,
    },
    versionToRegion: {
        [versions.france]: regionSupportedLanguageConfig.France,
        [versions.italy]: regionSupportedLanguageConfig.Italy,
        [versions.portugal]: regionSupportedLanguageConfig.Portugal,
        [versions.spain]: regionSupportedLanguageConfig.Spain,
        [versions.ukraine]: regionSupportedLanguageConfig.ukraine,
        [versions.germany]: regionSupportedLanguageConfig.germany,
        [versions.uk]: regionSupportedLanguageConfig["United Kingdom"],
        [versions.us]: regionSupportedLanguageConfig["United States"],
    },
    versionToHreflang: {
        [versions.france]: languages.fr,
        [versions.italy]: languages.it,
        [versions.portugal]: languages.pt,
        [versions.spain]: languages.es,
        [versions.ukraine]: languages.uk,
        [versions.germany]: languages.de,
        [versions.uk]: languages.en,
        [versions.us]: languages.en,
    },
};
