import { FC } from "react";
import classNames from "classnames/bind";

import { ContainerProps, ContainerSize } from "./Container.types";
import styles from "./Container.module.scss";

const cx = classNames.bind(styles);

const Container: FC<ContainerProps> = ({
    className,
    size = ContainerSize.DEFAULT,
    children,
}) => <div className={cx(className, "root", `size_${size}`)}>{children}</div>;
export default Container;
